function Footer(){
    return(
        <>
        <div className="footer-cont">
            mdavies@simplewebsitedesign.ca
            <img src="SWD.svg" className="swd-logo">

            </img>
        </div>
        </>
    )
}

export default Footer;