import "./App.css";
import React from "react";

// components
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";

import HomePage from "./Components/Pages/HomePage";
import Portfolio from "./Components/Pages/Portfolio";

// assets
import "./Assets/css/Header.css";
import "./Assets/css/Footer.css";
import "./Assets/css/HomePage.css";
import "./Assets/css/Portfolio.css";

import { Route, Routes } from "react-router-dom";

import { useEffect, useState } from "react";

function App() {
  /*
  const [isLoading, setIsLoading] = useState(true);

  const cacheImages = async (srcArray) => {
    const promises = await srcArray.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image();

        img.src = src;

        img.onload = resolve();
        img.onerror = reject();
      });
    });

    await Promise.all(promises);

    setIsLoading(false);
  };

  useEffect(() => {
    const frameCount = 661;
    const currentFrame = (index) =>
      `/image-sequence/simple-website-design-assembly-rev2${index
        .toString()
        .padStart(4, "0")}.jpg`;

    const images = [];

    for (let i = 0; i < frameCount; i++) {
      images.push(currentFrame(i));
    }

    //const imgs = [];
    cacheImages(images);
  }, []);
  */

  return (
    <div>
        <div>
          <Routes>
            <Route path="/" element={<HomePage />} />
          </Routes>
          <Footer />
        </div>
    </div>
  );
}

export default App;
